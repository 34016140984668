
import screenshotAvif from '../captura_pantalla.avif'
import screenshotPNG from '../captura_pantalla.png'
import Navbar from './Navbar'
import Footer from './Footer'
import Intro from './Articles/Intro'
import CMRDig from './Articles/CMRDig'
import './About.css'

import Desp from './Articles/Desp'
import React, { useState } from 'react';





const About = () => {

  var device

  let ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        device = "tablet";
      }
      if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        device = "mobile";
      }else{
        device = "desktop";
      }
      

  



  // Creamos un estado local para almacenar la lista de entradas y la entrada seleccionada
  const [blogList, setBlogList] = useState([
    { id: 1, title: '¿Qué es Drive-Team.es?', 
    summary: 'Saca provecho a los datos de tus tacógrafos. Traxain te ayuda usando los datos legalmente válidos del tacógrafo para calcular y controlar tus gastos en personal', 
    content: <Intro/> },
    { id: 2, title: '¿Qué pasa con el CMR digital?', 
    summary: 'Se está debatiendo en el Congreso una medida en el proyecto de ley de Movilidad Sostenible que establece la obligatoriedad de utilizar documentos de control digitales para el transporte, incluyendo el transporte internacional, a partir del 1 de octubre de 2024. Esta medida puede representar un avance en términos de transparencia y control en el sector del transporte.', 
    content: <CMRDig/> },
    { id: 3, title: 'Entendiendo la Directiva 1057/2020: Desplazamiento de trabajadores en transporte internacional por carretera', 
    summary: 'La Directiva 1057/2020 regula desplazamientos de trabajadores en transporte internacional, incluyendo cabotaje y triangular. Estos deben registrarse en IMI y seguir normas del Estado, especialmente salariales. Se requiere acceso a declaraciones, tacógrafo y cartas de porte durante el transporte.', 
    content: <Desp/> }]);

    let actualState 
    if(device==="mobile"){
      actualState = useState(null);
    } else {
      actualState = useState(blogList[0]);
    }
    const [selectedBlog, setSelectedBlog] = actualState;


  // Función que se ejecuta cuando se hace clic en una entrada de blog
  const handleBlogClick = (blog) => {
    window.scrollTo(0, 0)
    if (blog === selectedBlog && device==="mobile") {
      setSelectedBlog(null);
    } else {
      setSelectedBlog(blog);
    }
  };

  let BlogList


  if (device==="mobile"){

    console.log("about mobile")

                      BlogList = <div>
                                        <h1 nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"#5A5A5A",fontFamily:"Helvetica",fontWeight:"light"}}></h1>
                                        {/* Si se ha seleccionado una entrada de blog, la mostramos */}
                                        {selectedBlog && (
                                          <div>
                                            <p>{selectedBlog.content}</p>
                                          </div>
                                        )}
                                        <ul nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{listStyleType: "none"}}>
                                          {blogList.map((blog) => (
                                            <li key={blog.id} onClick={() => handleBlogClick(blog)}  className="clickable-element">
                                              <div id="content" className="mt-3">
                                                <div className="card mb-4" >
                                                  <div className="card-body" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ "width": "100%" }}>

                                                      <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{textAlign:"left",width:'100%'}}>
                                    
                                                          <tbody nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{textAlign:"left",width:'100%'}}>
                                                              <tr nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%',textAlign:"left"}}>
                                                                <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%',textAlign:"left"}}>
                                                                  <h3 nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"#5A5A5A",fontFamily:"Helvetica",fontWeight:"light"}}>
                                                                    {blog.title}
                                                                  </h3>
                                                                  <h5 nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"#5A5A5A",fontFamily:"Helvetica",fontWeight:"light",lineHeight: 1.5}}>
                                                                  <br></br><br></br>

                                                                  {blog.summary}...
                                                                  
                                                                  </h5>
                                                                  
                                                                  

                                                                  
                                                                </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                            </li>
                                          ))}
                                        </ul>
                                        
                                </div>


                    }else{
                      

                      BlogList = <div>
                                        <h1 nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"#5A5A5A",fontFamily:"Helvetica",fontWeight:"light"}}></h1>
                                        <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{textAlign:"left",width:'100%'}}>
                                    
                                    <tbody nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{textAlign:"top",width:'100%'}}>
                                        <tr nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%',textAlign:"top"}}>
                                          <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'30%',textAlign:"top"}}>
                                           

                                        <ul nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{listStyleType: "none"}}>
                                          {blogList.map((blog) => (
                                            <li key={blog.id} onClick={() => handleBlogClick(blog)}  className="clickable-element">
                                              <div id="content" className="mt-3">
                                                <div className="card mb-4" >
                                                  <div className="card-body" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ "width": "100%" }}>

                                                      <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{textAlign:"left",width:'100%'}}>
                                    
                                                          <tbody nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{textAlign:"left",width:'100%'}}>
                                                              <tr nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%',textAlign:"left"}}>
                                                                <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%',textAlign:"left"}}>
                                                                  <h3 nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"#5A5A5A",fontFamily:"Helvetica",fontWeight:"light"}}>
                                                                    {blog.title}
                                                                  </h3>
                                                                  <h5 nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"#5A5A5A",fontFamily:"Helvetica",fontWeight:"light",lineHeight: 1.5}}>
                                                                  <br></br><br></br>

                                                                  {blog.summary}...
                                                                  
                                                                  </h5>
                                                                  
                                                                  

                                                                  
                                                                </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                            </li>
                                          ))}
                                        </ul>
                                        </td>
                                        <td  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'5%',textAlign:"left"}}></td>
                                        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'60%',textAlign:"left"}}>
                                        {/* Si se ha seleccionado una entrada de blog, la mostramos */}
                                        {selectedBlog && (
                                          <div>
                                            <p>{selectedBlog.content}</p>
                                          </div>
                                        )}
                                        </td>
                                      </tr>
                                      </tbody>
                                      </table>
                                </div>


                    
                    }

    return(<div>
        <Navbar />
        
        <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize:"contain",
            backgroundPosition: "top",
      
          }}>
        <br></br><br></br><br></br><br></br>
        <tbody nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>
          <tr  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>
            <td  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'4%'}}>

            </td>

            {BlogList}

              <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'4%'}}>
              
              </td>
          </tr>
        </tbody>
        </table>
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>

        {/*<Footer />*/}
        </div>
            


    )
}


export default About