import React, { Component, useState } from 'react';
import 'react-dropdown/style.css';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { start } from '@popperjs/core';
import { DayPicker} from 'react-day-picker';
import { format } from 'date-fns';
import 'react-day-picker/dist/style.css';
import { es } from 'date-fns/locale';




class HolidayEmployee extends Component {
  
  componentDidMount(){
    this.getSavedDays()
  }

  constructor (props) {
    super(props)
    this.state = {
      savedDays : [],
      fechasSeleccionadas : [],
      errorMsg : ''
    };
    this.handleChangeDays = this.handleChangeDays.bind(this)
    this.saveDays = this.saveDays.bind(this)
  }

  onFormSubmit(e) {
    e.preventDefault();
  }

  handleChangeDays(fechaSeleccionada, {selected}) {
    try{
      let fechasSeleccionadas = this.state.fechasSeleccionadas;
      console.log(fechasSeleccionadas)
      console.log(fechaSeleccionada)
      let fechaIndex = fechasSeleccionadas.findIndex(fecha => fecha.getTime() === fechaSeleccionada.getTime()+3600000);

      if (fechaIndex === -1){
        fechaIndex = fechasSeleccionadas.findIndex(fecha => fecha.getTime() === fechaSeleccionada.getTime());
      }
      //Comprueba las horas que se han registrado a las 02:00
      if (fechaIndex === -1){
        fechaIndex = fechasSeleccionadas.findIndex(fecha => fecha.getTime() === fechaSeleccionada.getTime()+7200000);
      }
      //Comprueba las horas que se han registrado a las 03:00 y vuelvo
      if (fechaIndex === -1){
        fechaIndex = fechasSeleccionadas.findIndex(fecha => fecha.getTime() === fechaSeleccionada.getTime()+10800000);
      }

      console.log(fechaIndex)
      if (selected) {
        // Si la fecha ya está seleccionada, la eliminamos del array
        fechasSeleccionadas.splice(fechaIndex, 1);
      } else {
        // Si la fecha no está seleccionada, la agregamos al array
        fechasSeleccionadas.push(fechaSeleccionada);
      }
      this.setState({ fechasSeleccionadas });
    }
    catch(err){
      this.setState({ errorMsg: 'Error al seleccionar las fechas.' })
    }
  }

  /*Funcion para convertir las fechas del calendario en TimeStamp*/
  DatetoTimeStamp (fechasSeleccionadas){
    try{
      let savedDays = []
      for (let i=0;i<fechasSeleccionadas.length;i++){
        let days = (fechasSeleccionadas[i].getTime() / 1000)
        savedDays.push(days)
      }
      return savedDays
    }
    catch(err){
      this.setState({ errorMsg: 'Error en la conversion de fechas.' })
    }
    
  }

  /*Funcion para guardar los dias en el Back-End*/
  async saveDays (){
    try{
      //Obtenemos el endPoint, token, id empleado y rango de fechas
      let endPoint = this.props.endPoint
      let token = this.props.userToken
      let myEmployeeId = this.props.myEmployee.id
      let fechasSeleccionadas = this.state.fechasSeleccionadas
      let savedDays = this.DatetoTimeStamp(fechasSeleccionadas)

      
      let requestOptions = await {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify({
          "id": myEmployeeId,
          "holidays": savedDays
        }),
      };
      await this.setState({ loading: true })

      alert('¡Vacaciones registradas correctamente!')

      var response = await fetch(endPoint + "edit_holidays", requestOptions).then((response) => response.json())
    }catch(err){
      await this.setState({ errorMsg: 'Error al guardar las vacaciones' })

  }
    
  }

  async getSavedDays() {
    try{
      let endPoint = this.props.endPoint;
      let token = this.props.userToken;
      let myEmployeeId = this.props.myEmployee.id;
      let savedDays = this.state.savedDays
    
      let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify({
          "id": myEmployeeId,
          "holidays": savedDays
        }),
      };
    
      await this.setState({ loading: true });
    
      let response = await fetch(endPoint + "get_holidays", requestOptions).then((response) => response.json());

      for (let i=0;i<response.length;i++){
        let daysInit = new Date(response[i].init * 1000)
        savedDays.push(daysInit)
      }
      this.setState({fechasSeleccionadas : savedDays})
      this.setState({savedDays : savedDays})
      return savedDays
    }
    catch(err){
      await this.setState({ errorMsg: 'Error al obtener los dias de vacaciones registrados.' })
    }
     
  }

  render() {
    try{
      let holiday =
      <div id= "content" className="mt-3">
        <div className="card mb-4" >
          <div className="card-body" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>
          <form className="mb-3" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}} 
                onSubmit={(event) => {
                  event.preventDefault()
                }}>
              <div nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>
                <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>
                  <tbody nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>
                    <tr  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>
                      <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'75%', textAlign: 'center'}}>
                      <label className="float-left"><b>Calendario Laboral</b></label></td>
                    </tr>

                    <tr nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>
                      <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{display: 'flex', margin: '0 auto', alignItems: 'center', justifyContent: 'center'}}>
                        <DayPicker
                          locale={es}
                          onDayClick={this.handleChangeDays}
                          selected={this.state.savedDays}
                          selectedDays={this.state.fechasSeleccionadas}
                          onChange={this.handleChangeDays.bind(this)}
                        />
                      </td>
                    </tr>
                    <br></br>
                  </tbody>
                </table>
                    <button onClick={this.saveDays} type="submit" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" 
                        style={{display: 'flex', width:"75%", margin: '0 auto', justifyContent: 'center', alignItems: 'center'}
                              } className="btn btn-primary btn-block btn-lg">
                            Registrar Vacaciones
                    </button>
              </div>
            </form>
          </div>
        </div>
        
      </div>
      
      return (
        <div>
          {holiday}
        </div>
        
      )
      
    }catch(err){
      return(<div>Error al cargar el componente</div>)
    }
  }
}

 export default HolidayEmployee;