import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-dropdown/style.css';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';









class Creation extends Component {

  async componentDidMount() {
    try{
    // chech if myEmployee is empty

    let countries = [{ label: "España", value: "Spain" },
      { label: "Portugal", value: "Portugal" },
      { label: "Francia", value: "France" },
      { label: "Italia", value: "Italy" },
      { label: "Suiza", value: "Switzerland" },
      { label: "Austria", value: "Austria" },
      { label: "Bélgica", value: "Belgium" },
      { label: "Países Bajos", value: "Netherlands" },
      { label: "Alemania", value: "Germany" },
      { label: "Rep Checa", value: "Czechia" },
      { label: "Eslovaquia", value: "Slovakia" },
      { label: "Hungría", value: "Hungary" },
      { label: "Polonia", value: "Poland" },
      { label: "Rumania", value: "Romania" },
      { label: "Bulgaria", value: "Bulgaria" },
      { label: "Irlanda", value: "Ireland" },
      { label: "Grecia", value: "Greece" },
      { label: "Croacia", value: "Croatia" },
      { label: "Estonia", value: "Estonia" },
      { label: "Lituania", value: "Lithuania" },
      { label: "Luxemburgo", value: "Luxembourg" },
      { label: "Malta", value: "Malta" },
      { label: "Letonia", value: "Latvia" },
      { label: "Eslovenia", value: "Slovenia" },
      { label: "Finlandia", value: "Finland" },
      { label: "Suecia", value: "Sweden" },
      { label: "Dinamarca", value: "Denmark" },
      { label: "Chipre", value: "Cyprus" }


    ]

    this.setState({optionsCountries:countries})

    if (this.props.myEmployee === undefined || this.props.myEmployee==={}){

    }else{
      await this.setState({employeeName:this.props.myEmployee.name})
      await this.setState({employeeApellidos:this.props.myEmployee.apellido})
      await this.setState({identificador:this.props.myEmployee.identification})
      await this.setState({cardNumber:this.props.myEmployee.cardNumber})
      await this.setState({baseSalary:this.props.myEmployee.base_salary})
      await this.setState({weekly_hours:this.props.myEmployee.weekly_hours})
      await this.setState({nat_feed_diet:this.props.myEmployee.nat_feed_diet})
      await this.setState({nat_sleep_diet:this.props.myEmployee.nat_sleep_diet})
      await this.setState({int_feed_diet:this.props.myEmployee.int_feed_diet})
      await this.setState({int_sleep_diet:this.props.myEmployee.int_sleep_diet}) 
      await this.setState({nat_complete_diet:this.props.myEmployee.nat_complete_diet}) 
      await this.setState({nat_desayuno_diet:this.props.myEmployee.nat_desayuno_diet}) 
      await this.setState({nat_tarde_diet:this.props.myEmployee.nat_tarde_diet}) 
      await this.setState({nat_tarde_cama_diet:this.props.myEmployee.nat_tarde_cama_diet}) 
      await this.setState({nat_cena_diet:this.props.myEmployee.nat_cena_diet}) 
      await this.setState({nat_km_diet:this.props.myEmployee.nat_km_diet}) 
      await this.setState({int_complete_diet:this.props.myEmployee.int_complete_diet}) 
      await this.setState({int_desayuno_diet:this.props.myEmployee.int_desayuno_diet}) 
      await this.setState({int_tarde_diet:this.props.myEmployee.int_tarde_diet}) 
      await this.setState({int_tarde_cama_diet:this.props.myEmployee.int_tarde_cama_diet}) 
      await this.setState({int_cena_diet:this.props.myEmployee.int_cena_diet}) 
      await this.setState({int_km_diet:this.props.myEmployee.int_km_diet})
      await this.setState({festive_comp:this.props.myEmployee.festive_comp}) 
      await this.setState({weekend_comp:this.props.myEmployee.weekend_comp})

      if (this.props.myEmployee.country != ""){
        await this.setState({default_country:this.props.myEmployee.country})

      }
      
      await this.setState({mail:this.props.myEmployee.mail})
      await this.setState({phone:this.props.myEmployee.phone})

    }
  }catch(err){
    console.log("error")
  }
  }


  async handleChangeNationality(nat) {
    try{
    let nationality = await nat.value
    await this.setState({nationality: nationality})
    if (nationality === "Spain"){
      var optionsRegions= [

        { label: "Andalucía", value: "Andalucia" },
        { label: "Aragón", value: "Aragon" },
        { label: "Asturias", value: "Asturias" },
        { label: "Cantabria", value: "Cantabria" },
        { label: "Cataluña", value: "Cataluña" },
        { label: "Castilla y León", value: "Castilla-Leon" },
        { label: "Castilla-La Mancha", value: "Castilla-la-mancha" },
        { label: "Extremadura", value: "Extremadura" },
        { label: "Comunidad Valenciana", value: "Valencia" },
        { label: "Galicia", value: "Galicia" },
        { label: "Baleares", value: "Baleares" },
        { label: "Canarias", value: "Canarias" },
        { label: "La Rioja", value: "La Rioja" },
        { label: "Madrid", value: "Madrid" },
        { label: "Murcia", value: "Murcia" },
        { label: "Navarra", value: "Navarra" },
        { label: "País Vasco", value: "Pais Vasco" },

      ]

    } else{
      var optionsRegions= [

        { label: "Not spanish", value: "Not spanish" },

      ]

    }
    this.setState({optionsRegions:optionsRegions})

  }catch(err){
    console.log("error")
  }
    };

  async handleChangeRegion(reg) {
    try{
    let region = await reg.value
    await this.setState({region: region})
  }catch(err){
    console.log("error")
  }
    };

  constructor (props) {
    super(props)
    this.state = {
      optionsCountries:[],
      optionsRegions:[{ label: "Not spanish", value: "Not spanish" }],
      nationality:'',
      region:'',
      optionsRegions:[],
      employeeName:'',
      employeeApellidos : '',
      identificador:'',
      cardNumber:'',
      baseSalary:'',
      weekly_hours:'',
      nat_feed_diet:'',
      nat_sleep_diet:'',
      int_feed_diet:'',
      int_sleep_diet:'',
      default_country:'España',
      default_region:'',
      mail:'',
      phone:'',
      showSalDetails:false,
      nat_complete_diet:'',
      nat_desayuno_diet:'',
      nat_tarde_diet:'',
      nat_tarde_cama_diet:'',
      nat_cena_diet:'',
      nat_km_diet:'',
      int_complete_diet:'',
      int_desayuno_diet:'',
      int_tarde_diet:'',
      int_tarde_cama_diet:'',
      int_cena_diet:'',
      int_km_diet:'',
      festive_comp:'',
      weekend_comp:''
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleChangeNationality = this.handleChangeNationality.bind(this);
    this.handleChangeRegion = this.handleChangeRegion.bind(this);
 
  }


  async showSalDetails(){
    try{
    if(this.state.showSalDetails){
      await this.setState({showSalDetails:false})
    }else{
      await this.setState({showSalDetails:true})
 
    }
  }catch(err){
    console.log("error")
  }
  }







  onFormSubmit(e) {
    e.preventDefault();
    
  }

   render() {


    try{

let fields    





let creation

if (this.props.showSimulator){

  creation=
  <div className="card mb-4" >

  <div className="card-body" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>

  <form className="mb-3" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}} onSubmit={(event) => {
    
    event.preventDefault()
        let employeeName = this.employeeName.value
        let employeeApellidos= this.employeeApellidos.value
        let identificador= this.identificador.value
        let cardNumber= this.cardNumber.value
        let baseSalary= this.baseSalary.value
        if (isNaN(baseSalary)){
          baseSalary =parseFloat(baseSalary.replace(",","."))
        }
        let weekly_hours= this.weekly_hours.value
        if (isNaN(weekly_hours)){
          weekly_hours =parseFloat(weekly_hours.replace(",","."))
        }
        let nat_feed_diet= this.nat_feed_diet.value
        if (isNaN(nat_feed_diet)){
          nat_feed_diet =parseFloat(nat_feed_diet.replace(",","."))
        }
        let nat_sleep_diet= this.nat_sleep_diet.value
        if (isNaN(nat_sleep_diet)){
          nat_sleep_diet =parseFloat(nat_sleep_diet.replace(",","."))
        }
        let int_feed_diet= this.int_feed_diet.value
        if (isNaN(int_feed_diet)){
          int_feed_diet =parseFloat(int_feed_diet.replace(",","."))
        }
        let int_sleep_diet= this.int_sleep_diet.value
        if (isNaN(int_sleep_diet)){
          int_sleep_diet =parseFloat(int_sleep_diet.replace(",","."))
        }

        let nat_complete_diet = this.nat_complete_diet.value
        if (isNaN(nat_complete_diet)){
          nat_complete_diet =parseFloat(nat_complete_diet.replace(",","."))
        }
        let nat_desayuno_diet = this.nat_desayuno_diet.value
        if (isNaN(nat_desayuno_diet)){
          nat_desayuno_diet =parseFloat(nat_desayuno_diet.replace(",","."))
        }
        let nat_tarde_diet = this.nat_tarde_diet.value
        if (isNaN(nat_tarde_diet)){
          nat_tarde_diet =parseFloat(nat_tarde_diet.replace(",","."))
        }
        let nat_tarde_cama_diet = this.nat_tarde_cama_diet.value
        if (isNaN(nat_tarde_cama_diet)){
          nat_tarde_cama_diet =parseFloat(nat_tarde_cama_diet.replace(",","."))
        }
        let nat_cena_diet = this.nat_cena_diet.value
        if (isNaN(nat_cena_diet)){
          nat_cena_diet =parseFloat(nat_cena_diet.replace(",","."))
        }
        let nat_km_diet = this.nat_km_diet.value
        if (isNaN(nat_km_diet)){
          nat_km_diet =parseFloat(nat_km_diet.replace(",","."))
        }
        let int_complete_diet = this.int_complete_diet.value
        if (isNaN(int_complete_diet)){
          int_complete_diet =parseFloat(int_complete_diet.replace(",","."))
        }
        let int_desayuno_diet = this.int_desayuno_diet.value
        if (isNaN(int_desayuno_diet)){
          int_desayuno_diet =parseFloat(int_desayuno_diet.replace(",","."))
        }
        let int_tarde_diet = this.int_tarde_diet.value
        if (isNaN(int_tarde_diet)){
          int_tarde_diet =parseFloat(int_tarde_diet.replace(",","."))
        }
        let int_tarde_cama_diet = this.int_tarde_cama_diet.value
        if (isNaN(int_tarde_cama_diet)){
          int_tarde_cama_diet =parseFloat(int_tarde_cama_diet.replace(",","."))
        }
        let int_cena_diet = this.int_cena_diet.value
        if (isNaN(int_cena_diet)){
          int_cena_diet =parseFloat(int_cena_diet.replace(",","."))
        }
        let int_km_diet = this.int_km_diet.value

        if (isNaN(int_km_diet)){
          int_km_diet =parseFloat(int_km_diet.replace(",","."))
        }

        let festive_comp = this.festive_comp.value
        if (isNaN(festive_comp)){
          festive_comp =parseFloat(festive_comp.replace(",","."))
        }

        let weekend_comp = this.weekend_comp.value
        if (isNaN(weekend_comp)){
          weekend_comp =parseFloat(weekend_comp.replace(",","."))
        }

     


        
        
        let country=""
        let region = ""
        let phone = this.phone.value
        let mail = this.mail.value
       
        this.props.createEmployee(employeeName, employeeApellidos, identificador,cardNumber, baseSalary, weekly_hours, nat_feed_diet, nat_sleep_diet, int_feed_diet, int_sleep_diet, country, region, phone, mail,nat_complete_diet,nat_desayuno_diet,nat_tarde_diet,nat_tarde_cama_diet,nat_cena_diet,nat_km_diet,int_complete_diet,int_desayuno_diet,int_tarde_diet,int_tarde_cama_diet,int_cena_diet,int_km_diet,festive_comp,weekend_comp)
      
  }}>

    
 

  <table className=" table-borderless text-muted text-left" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"overflow-y": "scroll","width":"100%"}}>
    <tbody>
        <tr>
          <h6><b>Datos</b></h6>
        </tr>
        <tr style={{width:'100%'}}>
        <td style={{width:'48%'}}>
        Mail
        </td>
        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'4%'}}></td>
        <td><span className="text-muted" >Teléfono</span></td>
        
      </tr>
      <tr>
        <td><div>
        <input
            
            ref={(mail) => { this.mail = mail }}
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            className="form-control form-control-lg"
            defaultValue={this.state.mail}   
            required /></div>
        </td>
        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'10px'}}></td>
        <td ><div><input
            
            ref={(phone) => { this.phone = phone }}
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            className="form-control form-control-lg"
            defaultValue={this.state.phone}  
            required /></div>
          </td>
          
      </tr>
      <tr>
        <td>
        Nombre
        </td>
        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'10px'}}></td>
        <td><span className="text-muted">Apellidos</span></td>
      </tr>
      <tr>
        <td><div>
        <input
            
            ref={(employeeName) => { this.employeeName = employeeName }}
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            className="form-control form-control-lg"
            defaultValue={this.state.employeeName}
            required /></div>
</td>
        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'10px'}}></td>
        <td ><div><input
            
            ref={(employeeApellidos) => { this.employeeApellidos = employeeApellidos }}
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            className="form-control form-control-lg"
            defaultValue={this.state.employeeApellidos}
            required /></div>
          </td>
          
      </tr>
      <tr>
      </tr>
      
      <tr>
        <td><span className="text-muted">Identificador</span></td>
        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'10px'}}></td>
        <td><span className="text-muted">Número de Tarjeta</span></td>
      </tr>
      

      <tr>
        <td><div>
          <input
            
            ref={(identificador) => { this.identificador = identificador }}
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            className="form-control form-control-lg"
            defaultValue={this.state.identificador}
            required /></div>
</td>
        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'10px'}}></td>
        <td ><div>
          <input
            
            ref={(cardNumber) => { this.cardNumber = cardNumber }}
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            className="form-control form-control-lg"
            defaultValue={this.state.cardNumber}
            required /></div>
          </td>
      </tr>

     
<tr>
      <td>
        Salario Base
        </td>
        
        
      
      
        

      
      <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10px"}}>
              
              </td>
              
      <td>
     Horas Semanales
</td>

      </tr>
      <tr>
      <td>
      <input
            
            ref={(baseSalary) => { this.baseSalary = baseSalary }}
            className="form-control form-control-lg"
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            defaultValue={this.state.baseSalary}
            />
            </td>
            <td></td>
            <td>
            <input
            
            ref={(weekly_hours) => { this.weekly_hours = weekly_hours }}
            className="form-control form-control-lg"
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            defaultValue={this.state.weekly_hours}
            />
            
</td>


        </tr>
        <br></br>
        <tr>
          <h6><b>Dietas Nacionales</b></h6>
        </tr>
        
        <tr>
          <td>
          Almuerzo
          </td>
        
      
          <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10px"}}>
                
          </td>
              
          <td>
          Pernocta
          </td>

        </tr>
      <tr>
        <td>
        <input
              
              ref={(nat_feed_diet) => { this.nat_feed_diet = nat_feed_diet }}
              className="form-control form-control-lg"
              nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
              defaultValue={this.state.nat_feed_diet}
              />
        </td>
        <td>

        </td>
        <td>
          <input
            
            ref={(nat_sleep_diet) => { this.nat_sleep_diet = nat_sleep_diet }}
            className="form-control form-control-lg"
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            defaultValue={this.state.nat_sleep_diet}
            />
            
        </td>


      </tr>
              
      <tr>
          <td>
          Desayuno
          </td>
        
      
          <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10px"}}>
                
          </td>
              
          <td>
          Tarde
          </td>

        </tr>
      <tr>
        <td>
        <input
        ref={(nat_desayuno_diet) => { this.nat_desayuno_diet = nat_desayuno_diet }}
        className="form-control form-control-lg"
        nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
        defaultValue={this.state.nat_desayuno_diet}
        
              
           />
        </td>
        <td>

        </td>
        <td>
          <input
          ref={(nat_tarde_diet) => { this.nat_tarde_diet = nat_tarde_diet }}
          className="form-control form-control-lg"
          nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
          defaultValue={this.state.nat_tarde_diet}
          
            />
            
        </td>


      </tr>
              
      <tr>
          <td>
          Completa
          </td>
        
      
          <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10px"}}>
                
          </td>
              
          <td>
          Comida-tarde tarde-noche
          </td>

        </tr>
      <tr>
        <td>
        <input
             ref={(nat_complete_diet) => { this.nat_complete_diet = nat_complete_diet }}
             className="form-control form-control-lg"
             nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
             defaultValue={this.state.nat_complete_diet}
             
              />
        </td>
        <td>

        </td>
        <td>
          <input
            ref={(nat_tarde_cama_diet) => { this.nat_tarde_cama_diet = nat_tarde_cama_diet }}
            className="form-control form-control-lg"
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            defaultValue={this.state.nat_tarde_cama_diet}
            
          />
            
        </td>


      </tr>
      <tr>
          <td>
          Cena
          </td>
        
      
          <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10px"}}>
                
          </td>
              
          <td>
          Km
          </td>

        </tr>
      <tr>
        <td>
        <input
             ref={(nat_cena_diet) => { this.nat_cena_diet = nat_cena_diet }}
             className="form-control form-control-lg"
             nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
             defaultValue={this.state.nat_cena_diet}
              
           />
        </td>
        <td>

        </td>
        <td>
          <input
          ref={(nat_km_diet) => { this.nat_km_diet = nat_km_diet }}
          className="form-control form-control-lg"
          nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
          defaultValue={this.state.nat_km_diet}
          
            />
            
        </td>


      </tr>
      <br></br>
      <tr>
          <h6><b>Dietas Internacionales</b></h6>
      </tr>
      <tr>
        <td>
        Almuerzo
        </td>
   
        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10px"}}>
              
        </td>
              
        <td>
        Pernocta
        </td>

      </tr>
      <tr>
        <td>
          <input
                
                ref={(int_feed_diet) => { this.int_feed_diet = int_feed_diet }}
                className="form-control form-control-lg"
                nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
                defaultValue={this.state.int_feed_diet}
                />
        </td>
        <td>

        </td>
        <td>
          <input
            
            ref={(int_sleep_diet) => { this.int_sleep_diet = int_sleep_diet }}
            className="form-control form-control-lg"
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            defaultValue={this.state.int_sleep_diet}
            />
            
        </td>


      </tr>

        <tr>
          <td>
          Desayuno
          </td>
        
      
          <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10px"}}>
                
          </td>
              
          <td>
          Tarde
          </td>

        </tr>
        <tr>
        <td>
        <input
        ref={(int_desayuno_diet) => { this.int_desayuno_diet = int_desayuno_diet }}
        className="form-control form-control-lg"
        nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
        defaultValue={this.state.int_desayuno_diet}
        
              
           />
        </td>
        <td>

        </td>
        <td>
          <input
          ref={(int_tarde_diet) => { this.int_tarde_diet = int_tarde_diet }}
          className="form-control form-control-lg"
          nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
          defaultValue={this.state.int_tarde_diet}
          
          />
            
        </td>


      </tr>
              
      <tr>
          <td>
          Completa
          </td>
        
      
          <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10px"}}>
                
          </td>
              
          <td>
          Comida-tarde tarde-noche
          </td>

        </tr>
      <tr>
        <td>
        <input
             ref={(int_complete_diet) => { this.int_complete_diet = int_complete_diet }}
             className="form-control form-control-lg"
             nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
             defaultValue={this.state.int_complete_diet}
             
              />
        </td>
        <td>

        </td>
        <td>
          <input
            ref={(int_tarde_cama_diet) => { this.int_tarde_cama_diet = int_tarde_cama_diet }}
            className="form-control form-control-lg"
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            defaultValue={this.state.int_tarde_cama_diet}
            
          />
            
        </td>


      </tr>
      <tr>
          <td>
          Cena
          </td>
        
      
          <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10px"}}>
                
          </td>
              
          <td>
          Km
          </td>

        </tr>
      <tr>
        <td>
        <input
             ref={(int_cena_diet) => { this.int_cena_diet = int_cena_diet }}
             className="form-control form-control-lg"
             nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
             defaultValue={this.state.int_cena_diet}
              
           />
        </td>
        <td>

        </td>
        <td>
          <input
          ref={(int_km_diet) => { this.int_km_diet = int_km_diet }}
          className="form-control form-control-lg"
          nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
          defaultValue={this.state.int_km_diet}
          
            />
            
        </td>


      </tr>
      <br></br>
      <tr>
          <h6><b>Complementos</b></h6>
      </tr>
      <tr>
        <td>
        Festivos
        </td>
   
        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10px"}}>
              
        </td>
              
        <td>
        Fines de Semana
        </td>

      </tr>
      <tr>
        <td>
          <input
                
                ref={(festive_comp) => { this.festive_comp = festive_comp }}
                className="form-control form-control-lg"
                nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
                defaultValue={this.state.festive_comp}
                />
        </td>
        <td>

        </td>
        <td>
          <input
            
            ref={(weekend_comp) => { this.weekend_comp = weekend_comp }}
            className="form-control form-control-lg"
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            defaultValue={this.state.weekend_comp}
            />
            
        </td>


      </tr>   
   
    </tbody>
    </table>

    <br></br>
    


  <button type="submit" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"100%"}} className="btn btn-primary btn-block btn-lg">Registrar</button>
  </form>
  </div>
  </div>
}else{


   creation=
      <div className="card mb-4" >

      <div className="card-body" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>

      <form className="mb-3" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}} onSubmit={(event) => {
        
        event.preventDefault()
        let employeeName = this.employeeName.value
        let employeeApellidos= this.employeeApellidos.value
        let identificador= this.identificador.value
        let cardNumber= this.cardNumber.value
        let baseSalary= this.baseSalary.value

        let weekly_hours= this.weekly_hours.value
        
        if (baseSalary===""){
          baseSalary = 1500
        }
        if (weekly_hours===""){
          weekly_hours = 40
        }


        if (isNaN(baseSalary)){
          baseSalary =parseFloat(baseSalary.replace(",","."))
        }
        
        
        if (isNaN(weekly_hours)){
          weekly_hours =parseFloat(weekly_hours.replace(",","."))
        }
        
       
        let nat_feed_diet= -1
        
        
        let nat_sleep_diet= -1
       
        let int_feed_diet= -1
       
        let int_sleep_diet= -1
        

        let nat_complete_diet = -1
        
        let nat_desayuno_diet = -1
        
        let nat_tarde_diet = -1
        
        let nat_tarde_cama_diet = -1
        
        let nat_cena_diet = -1
        
        let nat_km_diet = -1
        
        let int_complete_diet = -1
       
        let int_desayuno_diet = -1
        
        let int_tarde_diet = -1
       
        let int_tarde_cama_diet = -1
       
        let int_cena_diet = -1
        
        let int_km_diet = -1

        let festive_comp = -1
        
        let weekend_comp = -1

        

     
        
        
        let country=""
        let region = ""
        let phone = this.phone.value
        let mail = this.mail.value
       
        this.props.createEmployee(employeeName, employeeApellidos, identificador,cardNumber, baseSalary, weekly_hours, nat_feed_diet, nat_sleep_diet, int_feed_diet, int_sleep_diet, country, region, phone, mail,nat_complete_diet,nat_desayuno_diet,nat_tarde_diet,nat_tarde_cama_diet,nat_cena_diet,nat_km_diet,int_complete_diet,int_desayuno_diet,int_tarde_diet,int_tarde_cama_diet,int_cena_diet,int_km_diet, festive_comp, weekend_comp)
      
      }}>

        
     

      <table className=" table-borderless text-muted text-left" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"overflow-y": "scroll","width":"100%"}}>
        <tbody>
        <tr>
          <h6><b>Datos</b></h6>
        </tr>
    <tr style={{width:'100%'}}>
        <td style={{width:'48%'}}>
        Mail
        </td>
        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'4%'}}></td>
        <td><span className="text-muted" style={{width:'48%'}}>Teléfono</span></td>
        
      </tr>
      <tr>
        <td><div>
        <input
            
            ref={(mail) => { this.mail = mail }}
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            className="form-control form-control-lg"
            defaultValue={this.state.mail}   
            required /></div>
        </td>
        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'10px'}}></td>
        <td ><div><input
            
            ref={(phone) => { this.phone = phone }}
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            className="form-control form-control-lg"
            defaultValue={this.state.phone}  
            required /></div>
          </td>
          
      </tr>
      <tr>
        <td>
        Nombre
        </td>
        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'10px'}}></td>
        <td><span className="text-muted">Apellidos</span></td>
      </tr>
      <tr>
        <td><div>
        <input
            
            ref={(employeeName) => { this.employeeName = employeeName }}
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            className="form-control form-control-lg"
            defaultValue={this.state.employeeName}
            required /></div>
</td>
        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'10px'}}></td>
        <td ><div><input
            
            ref={(employeeApellidos) => { this.employeeApellidos = employeeApellidos }}
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            className="form-control form-control-lg"
            defaultValue={this.state.employeeApellidos}
            required /></div>
          </td>
          
      </tr>
      <tr>
      </tr>
      
      <tr>
        <td><span className="text-muted">Identificador</span></td>
        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'10px'}}></td>
        <td><span className="text-muted">Número de Tarjeta</span></td>
      </tr>
      

      <tr>
        <td><div>
          <input
            
            ref={(identificador) => { this.identificador = identificador }}
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            className="form-control form-control-lg"
            defaultValue={this.state.identificador}
            required /></div>
</td>
        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'10px'}}></td>
        <td ><div>
          <input
            
            ref={(cardNumber) => { this.cardNumber = cardNumber }}
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            className="form-control form-control-lg"
            defaultValue={this.state.cardNumber}
            required /></div>
          </td>
      </tr>

     
<tr>
      <td>
        Salario Base
        </td>
        
        
      
      
        

      
      <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10px"}}>
              
              </td>
              
      <td>
     Horas Semanales
</td>

      </tr>
      <tr>
      <td>
      <input
            
            ref={(baseSalary) => { this.baseSalary = baseSalary }}
            className="form-control form-control-lg"
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            defaultValue={this.state.baseSalary}
            />
            </td>
            <td></td>
            <td>
            <input
            
            ref={(weekly_hours) => { this.weekly_hours = weekly_hours }}
            className="form-control form-control-lg"
            nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
            defaultValue={this.state.weekly_hours}
            />
            
</td>


        </tr>
            {/*
            <tr>
              <h6><b>Dietas Nacionales</b></h6>
            </tr>
            
            <tr>
          <td>
            Manutención
            </td>
            
            
          
          
            

          
          <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10px"}}>
                  
                  </td>
                  
          <td>
          Pernocta
    </td>
    
          </tr>
          <tr>
          <td>
          <input
                
                ref={(nat_feed_diet) => { this.nat_feed_diet = nat_feed_diet }}
                className="form-control form-control-lg"
                nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
                defaultValue={this.state.nat_feed_diet}
                required />
                </td>
                <td></td>
                <td>
                <input
                
                ref={(nat_sleep_diet) => { this.nat_sleep_diet = nat_sleep_diet }}
                className="form-control form-control-lg"
                nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
                defaultValue={this.state.nat_sleep_diet}
                required />
                
    </td>
   

            </tr>
            <tr>
              <h6><b>Dietas Internacionales</b></h6>
            </tr>
            <tr>
          <td>
            Manutención
            </td>
            
            
          
          
            

          
          <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10px"}}>
                  
                  </td>
                  
          <td>
         Pernocta
    </td>
    
          </tr>
          <tr>
          <td>
          <input
                
                ref={(int_feed_diet) => { this.int_feed_diet = int_feed_diet }}
                className="form-control form-control-lg"
                nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
                defaultValue={this.state.int_feed_diet}
                required />
                </td>
                <td></td>
                <td>
                <input
                
                ref={(int_sleep_diet) => { this.int_sleep_diet = int_sleep_diet }}
                className="form-control form-control-lg"
                nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
                defaultValue={this.state.int_sleep_diet}
                required />
                
    </td>
   

            </tr>
            <tr>
            
            
            <td>
            País

            </td>
            <td>
              
              </td>
              <td>
              Comunidad (solo para España)
              </td>
            </tr>
            <tr>
              <td>
              <Select 
                          options={this.state.optionsCountries} 
                          onChange={ this.handleChangeNationality}
                          defaultValue={this.state.default_country} 
                          />


              </td>
              <td>

              </td>
              <td>
              <Select 
                          options={this.state.optionsRegions} 
                          onChange={ this.handleChangeRegion}
                          defaultValue={this.state.default_region}   
                          />
                
              </td>
            </tr> 
        
    */}
       
        </tbody>
        </table>

        <br></br>
        


      <button type="submit" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"100%"}} className="btn btn-primary btn-block btn-lg">Registrar</button>
      </form>
      </div>
      </div>
}
      
    
     
        return (
          <div>
           
  {creation}
          
            </div>
        )
      }catch(err){
        return(<div>Error al cargar el componente Creación</div>)
      }
      }
 }

 export default Creation;