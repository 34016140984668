import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import Select from 'react-select';









class TripCreation extends Component {

  constructor (props) {
    super(props)
    this.state = {
      startDate: new Date(),
      tripDate:  new Date(),
      endDate:new Date(),
      tripDateInt: 0,
      time:{label: "06" , value:6},

      //verifier:'0x35386EEf4f0114Aa603Ec2E42FD00275f385C838',

      //bufferday:7,
      //options : [
      //  '0x35386EEf4f0114Aa603Ec2E42FD00275f385C838', '0x37024244E62F2B430e7B0eA207373076d66C435b', '0xBdc50027c1CC234C6f30838656D969365de91a2b'
      //],
      defaultOption : '',
      //payday:7,
      tripdate:0,
      origin:'',
      destination:'',
      alreadyLoaded:false,
      //payWithCard:false,
      reloads:0,
      selectedModalidad:"Completa",
      modalidad:"complete",
      //askCheckout:''
    };
    this.handleChangeTrip = this.handleChangeTrip.bind(this);
    //this.handleChangeBuff = this.handleChangeBuff.bind(this);
    //this.handleChangePay = this.handleChangePay.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    //this.handleChangeVer = this.handleChangeVer.bind(this);
    //this.handleChangeCard = this.handleChangeCard.bind(this);
    this.setTime = this.setTime.bind(this);
  }


 async handleChangeModalidad(modalidad){

  try{

  await this.setState({selectedModalidad:modalidad.label})
  await this.setState({modalidad:modalidad.value})
}catch(err){
  this.props.showError()
}
 }

async setTime(newTime){

  try{
  await this.setState({time:newTime})

  let hora = newTime.value * 3600

  let newTripDate =this.state.tripDateInt+hora

  await this.setState({tripDateInt:newTripDate})
}catch(err){
  this.props.showError()
}
}


  async handleChangeTrip(dateTripp) {

   try{
let tripRounded = new Date(dateTripp.toDateString());
  
 let tripDateInt = parseInt(tripRounded.getTime())/1000
    this.setState({tripDate: dateTripp});
    this.setState({tripDateInt: tripDateInt})
   
  //  let rowCurrentDate =await  new Date()
  //  let currentDate =await  Date.parse(rowCurrentDate)
    
    
   //   if ( dateTripp < currentDate) {
   //     alert("Por favor, por una fecha en el futuro")
  //    }else{
     
 //   let formattedDate =await  Date.parse(dateTripp)
    
  //  let trip =await  Math.round((formattedDate - currentDate)/(3600000*24))

}catch(err){
  this.props.showError()
}
  }
//} catch(err) {
 // alert("No puedes hacer eso")
//}
 // }



 

  onFormSubmit(e) {
    e.preventDefault();
    
  }

    render() {

      try{
let creation


        
        creation=<div id= "content" className="mt-3">
      <div className="card mb-4" >

      <div className="card-body" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>

      <form className="mb-3" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}} onSubmit={(event) => {
      
        event.preventDefault()
    
        
        let origin =this.origin.value
        let destination =this.destination.value
        let tripDateInt =this.state.tripDateInt
        let tripDateIntNull = new Date().getTime()
        let mode = this.state.modalidad
        if(mode === ""){
          mode = "complete"
        }
        if(tripDateInt === 0){
          tripDateInt =Math.floor(tripDateIntNull/1000)
        }
      
      

        this.props.createTrip(origin,destination,tripDateInt, mode)
   
      }}>

        
      <div nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>
        <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>
          <tbody nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>
            <tr  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>
            <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'35%'}}><label className="float-left"><b>Crear servicio</b></label></td>
            <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'40%'}}></td>
            <td  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'25%',textAlign:'right',}}><span className="text-muted" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{textAlign:'right',width:'100%'}}>
        
        </span></td>
        </tr>
          </tbody>

        </table>
      </div>

      <table className=" table-borderless text-muted text-left">
        <tbody>


          <tr>
            <td><span className="text-muted">Origen</span></td>
            <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'20px'}}></td>
            <td><span className="text-muted">Destino</span></td>
          </tr>
          
          <tr></tr>

          <tr>
            <td><div className="input-group mb-4">
      
      <input
        
        ref={(origin) => { this.origin = origin }}
        className="form-control form-control-lg"
        placeholder="0"
        required />
    </div>
    </td>
            <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'20px'}}></td>
            <td ><div className="input-group mb-4">
              <input
                
                ref={(destination) => { this.destination = destination }}
                className="form-control form-control-lg"
                placeholder="0"
                required /></div>
              </td>
          </tr>
          <tr>
            <td>
            Fecha de servicio
            
            </td>
            <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'20px'}}></td>
            <td><span className="text-muted">Modalidad</span></td>
          </tr>
          <tr>
            <td >
            <DatePicker
              className="form-control form-control-lg"
              selected={ this.state.tripDate }
              dateFormat="yyyy/MM/dd"
              onChange={ this.handleChangeTrip }
              placeholder="0"
              required 
              />
            
    </td>
            <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'20px'}}></td>
            <td >
            {/*<Select
                            value={this.state.selectedModalidad}
                            styles={{color:"grey",width:"100%"}}
                            className="form-control-lg"
                            onChange={(modalidad) => this.handleChangeModalidad(modalidad)}
                            options={[
                              
                            {label:"Completa", value: "complete"},
                            {label:"Grupaje", value: "partial"}, 
                            {label:"En vacío", value: "empty"}]}
                            />*/}
                <Dropdown 
                  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{height:"100%",width:"100%"}}
                  options={[
                              
                    {label:"Completa", value: "complete"},
                    //{label:"Grupaje", value: "partial"}, 
                    {label:"En vacío", value: "empty"}]} 
                  onChange={(modalidad) => this.handleChangeModalidad(modalidad)}
                 
                  
                  
                  //value={this.state.defaultOption} 
                  
                  value={this.state.selectedModalidad}
                  />
            
            
              </td>
          </tr>

          <tr>
            <td >
            Hora
            
    </td>
            <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'20px'}}></td>
            <td >
           
            
            
              </td>
          </tr>
          
          <tr>
            <td >
            
              <Dropdown
                styles={{height:"100%",width:"100%"}}
                value={this.state.time}
                options={[{label: "00" , value:0},
                          {label: "01" , value:1},
                          {label: "02" , value:2},
                          {label: "03" , value:3},
                          {label: "04" , value:4},
                          {label: "05" , value:5},
                          {label: "06" , value:6},
                          {label: "07" , value:7},
                          {label: "08" , value:8},
                          {label: "09" , value:9},
                          {label: "10" , value:10},
                          {label: "11" , value:11},
                          {label: "12" , value:12},
                          {label: "13" , value:13},
                          {label: "14" , value:14},
                          {label: "15" , value:15},
                          {label: "16" , value:16},
                          {label: "17" , value:17},
                          {label: "18" , value:18},
                          {label: "19" , value:19},
                          {label: "20" , value:20},
                          {label: "21" , value:21},
                          {label: "22" , value:22},
                          {label: "23" , value:23}]}
                onChange={(newValue) => {
                  this.setTime(newValue);
                }}
                //renderInput={(params) => <TextField {...params} />}
              />
    </td>
            <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'20px'}}></td>
            <td >
            {/*<Select
                            value={this.state.selectedModalidad}
                            styles={{color:"grey",width:"100%"}}
                            className="form-control-lg"
                            onChange={(modalidad) => this.handleChangeModalidad(modalidad)}
                            options={[
                              
                            {label:"Completa", value: "complete"},
                            {label:"Grupaje", value: "partial"}, 
                            {label:"En vacío", value: "empty"}]}
                            />*/}
                
            
            
              </td>
          </tr>
          <tr> 
            <td>
              
            </td>
            <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"20px"}}>
                  
            </td>
            <td>
            </td>
          </tr>
     
        
    <br></br>
       
        </tbody>
        </table>

   
        


      <button type="submit" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"100%"}} className="btn btn-primary btn-block btn-lg">Crear</button>
      </form>
      </div>
      </div>
     

      </div>
    
    
     
        return (
          <div>
           
  {creation}
          
            </div>
        )
      }catch(err){
        return(<div>Error al cargar el componente</div>)
      }
      }
 }

 export default TripCreation;