import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';

class ModalTrip extends Component {
    
    constructor (props) {
        super(props)
        this.state = {
        tripDate:  new Date(),
        tripDateInt: 0,
        time:{label: "06:00" , value:6},
        dateTimeTrip: new Date(this.props.dateTrip*1000),

        tripdate:0,
        origin:'',
        destination:'',
        alreadyLoaded:false,

        selectedModalidad:"",
        modalidad:"",

        };
        this.handleChangeTrip = this.handleChangeTrip.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.setTime = this.setTime.bind(this);
    }


    async handleChangeModalidad(modalidad){
        try{
            await this.setState({selectedModalidad:modalidad.label})
            await this.setState({modalidad:modalidad.value})
        }catch(err){
            this.props.showError()
        }
    }

    async setTime(newTime){
        try{
            await this.setState({time:newTime})
            let hora = newTime.value * 3600
            let newTripDate =this.state.tripDateInt+hora
            await this.setState({tripDateInt:newTripDate})
        }catch(err){
            this.props.showError()
        }
    }


    async handleChangeTrip(dateTripp) {
        try{
            let tripRounded = new Date(dateTripp.toDateString());
            let tripDateInt = parseInt(tripRounded.getTime())/1000
            this.setState({dateTimeTrip: dateTripp});
            this.setState({tripDateInt: tripDateInt})
        }catch(err){
            this.props.showError()
        }
    }

    onFormSubmit(e) {e.preventDefault();}

    render() {
        try{
            var show = this.props.show
            var children = this.props.children
            var showHideClassName = show ? "modal display-block" : "modal display-none";
            let originTrip = this.props.originTrip
            let destinationTrip = this.props.destinationTrip
            let modeTrip = this.props.modeTrip

            let creation= 
                <form className="mb-3" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'90%', marginLeft:"5%", marginRight:"5%", display:"flex", justifyContent:"center", alignContent:"center"}} onSubmit={(event) => {
                    event.preventDefault()
                    
                    let origin =this.origin.value
                    let destination =this.destination.value
                    let tripDateInt =this.state.tripDateInt
                    let mode = this.state.modalidad
                    if(mode === ""){
                        mode = modeTrip
                    }
                    if(tripDateInt === 0){
                        tripDateInt = this.props.dateTrip
                    }
                    
                    this.props.editTripDetails(origin,destination,tripDateInt, mode)
                    this.props.onClose
                }}>
                    <table className=" table-borderless text-muted text-left">
                        <tbody>
                            <tr> 
                                <table className=" table-borderless text-muted text-left">
                                    <tbody>
                                        <tr>
                                            <td><span className="text-muted">Origen</span></td>
                                            <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'6%'}}></td>
                                            <td><span className="text-muted">Destino</span></td>
                                        </tr>
                                        <tr></tr>
                                        <tr>
                                            <td>
                                                <input
                                                ref={(origin) => { this.origin = origin }}
                                                className="form-control form-control-lg"
                                                placeholder={originTrip}
                                                defaultValue={originTrip}
                                                required />
                                            </td>
                                            <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'6%'}}></td>
                                            <td>
                                                <input
                                                ref={(destination) => { this.destination = destination }}
                                                className="form-control form-control-lg"
                                                placeholder={destinationTrip}
                                                defaultValue={destinationTrip}
                                                required />
                                            </td>
                                        </tr>
                                        <br></br>
                                        <tr>
                                            <td>Hora de salida</td>
                                            <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'6%'}}></td>
                                            <td><span className="text-muted">Modalidad</span></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Dropdown
                                                styles={{height:"100%",width:"100%"}}
                                                value={this.state.time} 
                                                options={[{label: "00:00" , value:0},
                                                        {label: "01:00" , value:1},
                                                        {label: "02:00" , value:2},
                                                        {label: "03:00" , value:3},
                                                        {label: "04:00" , value:4},
                                                        {label: "05:00" , value:5},
                                                        {label: "06:00" , value:6},
                                                        {label: "07:00" , value:7},
                                                        {label: "08:00" , value:8},
                                                        {label: "09:00" , value:9},
                                                        {label: "10:00" , value:10},
                                                        {label: "11:00" , value:11},
                                                        {label: "12:00" , value:12},
                                                        {label: "13:00" , value:13},
                                                        {label: "14:00" , value:14},
                                                        {label: "15:00" , value:15},
                                                        {label: "16:00" , value:16},
                                                        {label: "17:00" , value:17},
                                                        {label: "18:00" , value:18},
                                                        {label: "19:00" , value:19},
                                                        {label: "20:00" , value:20},
                                                        {label: "21:00" , value:21},
                                                        {label: "22:00" , value:22},
                                                        {label: "23:00" , value:23}]}
                                                onChange={(newValue) => {
                                                this.setTime(newValue);
                                                }}
                                                />                                   
                                            </td>
                                            <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'6%'}}></td>
                                            <td>
                                                <Dropdown 
                                                nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{height:"100%",width:"100%"}}
                                                options={[{label:"Completa", value: "complete"}, {label:"En vacío", value: "empty"}]} 
                                                onChange={(modalidad) => this.handleChangeModalidad(modalidad)}
                                                value={modeTrip}
                                                defaultValue={modeTrip}
                                                />
                                            </td>
                                        </tr>
                                        <br></br>
                                    </tbody>
                                </table>
                                <table className=" table-borderless" style={{width:"100%", display:"flex", justifyContent:"center", alignContent:"center"}}>
                                    <tbody>
                                        <tr>
                                            <td style={{width:"47%"}}>Fecha de servicio</td>
                                        </tr>
                                        <tr>
                                            <td style={{width:"47%"}}>
                                                <DatePicker
                                                style={{textAlign:"center"}}
                                                className="form-control form-control-lg"
                                                selected={ this.state.dateTimeTrip }
                                                dateFormat="yyyy/MM/dd"
                                                onChange={ this.handleChangeTrip }
                                                required 
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br></br>
                                <table className=" table-borderless" style={{width:"100%"}}>
                                    <tbody style={{width:"100%"}}>
                                        <tr style={{width:"100%"}}>
                                        <td style={{width:"47%"}}>
                                                <button type="submit" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"100%", border: "none"}} className="btn btn-primary btn-block btn-lg">Guardar</button>
                                            </td>
                                            <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"6%"}}></td>
                                            <td style={{width:"47%"}}>
                                                <button type="button" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"100%", backgroundColor: "red", border: "none"}} className="btn btn-primary btn-block btn-lg" onClick={this.props.onClose}>Cerrar</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </tr>
                        </tbody>
                    </table>
                </form>

    
    
     
            return (
                <div className={showHideClassName} nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ textAlign: "center"}}>
                    <section className="modal-main" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ textAlign: "center", width:"50%", borderRadius:"10px", border:"1px solid silver" }}>
                        <br></br>
                        <h5>{children}</h5>
                        {creation}
                        <br></br>
                    </section>
                </div>)

        }catch(err){
            return(<div>Error al cargar el componente</div>)
        }
    }
 }

 export default ModalTrip;