import React from "react";
import Chart from "chart.js/auto";

class SMSBarChart extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        this.createSMSBarChart();
    }

    createSMSBarChart = async () => {
        const response = this.props.dataGraphic.filter(employee => employee.sent_sms !== 0);
        //const response = this.props.dataGraphic;
        const popCanvas = this.chartRef.current.getContext("2d");
        
        

        // Destroy existing chart if it exists
        if (this.chartInstance) {
            this.chartInstance.destroy();
        }
        
        
        const datasets = [{
            label: "Envío de SMS",
            data: response.map(employee => employee.sent_sms),
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
        },
         {
            label: "Respuesta de SMS",
            data: response.map(employee => employee.resp_sms),
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgb(54, 162, 235)',
            borderWidth: 1,
        }
        ];
    
        // Update the chart options
        this.chartInstance = new Chart(popCanvas, {
            type: 'bar',
            data: {
                labels: response.map(employee => employee.employee_name),
                datasets: datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        width: '100%',
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            boxWidth: 20,
                            padding: 10,
                            usePointStyle: true,
                        },
                        maxItems: 3,
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Empleados',
                        },
                        type: 'category',
                        position: 'bottom',
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Cantidad de SMS',
                        },
                        type: 'linear',
                        position: 'left',
                    },
                },
            },
        });
    };
    

    render() {
        const chartContainerStyle = {
            maxHeight: '70vh', // 80% of the viewport height
            overflow: 'auto', // Make it scrollable
        };

        return (
            <div style={chartContainerStyle}>    
                <canvas ref={this.chartRef} style={{width:"70vh", height:"70vh"}}></canvas>
            </div>
        );
    }
}

export default SMSBarChart;