import React, { Component } from 'react';
import 'react-dropdown/style.css';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorMessage from "./ErrorMessage";

class CreationManager extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mail:'',
      username:'',
      password1:'',
      password2:'',
      errorMessage:''
    };

    this.onFormSubmit = this.onFormSubmit.bind(this); 
  }

  onFormSubmit(e) {
    e.preventDefault();
  }

  render() {
    try{  
      let creation

      creation=
        <div className="card mb-4" >
          <div className="card-body" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}}>
            <form className="mb-3" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'100%'}} onSubmit={(event) => {
              event.preventDefault()       
              let mail = this.mail.value 
              let username = this.username.value
              let password1 = this.password1.value
              let password2 = this.password2.value

              const CryptoJS = require("crypto-js");
              const salt = "farm1990M0O";
              const salt1 = "f1nd1ngn3m0";
              const hashPassword = CryptoJS.SHA256(salt1 + password1 + salt).toString();

              if(password1===password2 && password1.length>=8){
                this.props.createManager(mail, hashPassword, username)
              } else {
                this.setState({errorMessage:"La contraseña debe coincidir y tener al menos 8 caracteres"})
              }   
            }}>
              <table className=" table-borderless text-muted text-left" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"overflow-y": "scroll","width":"100%"}}>
                <tbody>
                  <tr>
                    <h6><b>Datos</b></h6>
                  </tr>
                  <tr style={{width:'100%'}}>
                    <td style={{width:'48%'}}>Mail</td>
                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'4%'}}></td>
                    <td><span className="text-muted" style={{width:'48%'}}>Nombre</span></td>                 
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <input
                          ref={(mail) => { this.mail = mail }}
                          nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
                          className="form-control form-control-lg"
                          defaultValue={this.state.mail}   
                          required/>
                      </div>
                    </td>
                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'10px'}}></td>
                    <td>
                      <div>
                        <input
                          ref={(username) => { this.username = username }}
                          nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
                          className="form-control form-control-lg"
                          defaultValue={this.state.username}   
                          required/>
                      </div>
                    </td>     
                  </tr>
                  <tr>
                    <td>Contraseña</td>
                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'10px'}}></td>
                    <td><span className="text-muted">Confirme la contraseña</span></td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <input
                          type="password"                        
                          ref={(password1) => { this.password1 = password1 }}
                          nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
                          className="form-control form-control-lg"
                          defaultValue={this.state.password1}
                          required/>
                      </div>
                    </td>
                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:'10px'}}></td>
                    <td>
                      <div>
                        <input
                          type="password"
                          ref={(password2) => { this.password2 = password2 }}
                          nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
                          className="form-control form-control-lg"
                          defaultValue={this.state.password2}
                          required/>
                      </div>
                    </td>                
                  </tr>
                </tbody>
              </table>
              <br></br>
              <button type="submit" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"100%"}} className="btn btn-primary btn-block btn-lg">Registrar</button>
            </form>
          </div>
        </div>

      return (
        <div>
          {creation}
          <div style={{textAlign: "center"}}>
            <ErrorMessage message={this.state.errorMessage}/>
          </div>
        </div>
        )
    }catch(err){
      return(<div>Error al cargar el componente Creación</div>)
    }
  }
}

export default CreationManager;