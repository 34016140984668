import React, { Component } from "react";
import './modal.css';
import 'react-dropdown/style.css';

class SendSmsActivity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            comments: 'Comentario',
            empId: 'EmpId'
        };
    }

    async componentDidMount() {
        await this.setState({ comments: 'Comentario', empId: 'EmpId' })
    }

    async editSendSms(comments) {
        await this.setState({ comments: comments })
    }

    render() {
        // try {
            var show = this.props.show
            var children = this.props.children

            var showHideClassName = show ? "modal display-block" : "modal display-none";

            //Formateo de los datos
            let date = new Date(this.props.selectedActivityDict.start*1000)
            let formatDate = date.toLocaleString();  

            let type = this.props.selectedActivityDict.type
            let typeFormat;
            switch (type) {
                case "Rest":
                    typeFormat = "Descanso";
                    break;
                case "Working":
                    typeFormat = "Trabajando";
                    break;
                case "Driving":
                    typeFormat = "Conducción";
                    break;
                case "Available":
                    typeFormat = "Disponible";
                    break;
                case "Desconocido":
                    typeFormat = "Desconocido";
                    break;
            }

            let location = this.props.selectedActivityDict.place
            let formatLocation = location

            if (location == "Spain") {
                let region = this.props.selectedActivityDict.region
                formatLocation = location + ", " +  region
            }

            return (
                <div className={showHideClassName} nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ textAlign: "center" }}>
                    <section className="modal-main" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ textAlign: "center", borderRadius: "10px", border: "1px solid silver" }}>
                        <h5>{children}</h5>
                        <form nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ textAlign: "center" }} onSubmit={(event) => {
                            event.preventDefault();
                            let comments = this.comments.value;
                            let empId;
                            let start = this.props.start;
                            let end = this.props.end;

                            try {
                                empId = this.props.myEmployee.id;
                            } catch {
                                empId = this.props.selectedActivityDict['employee_id'];
                            }

                            this.props.sendMessage(empId, start, end, comments);

                            this.props.onClose();

                        }}>
                            <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "100%", textAlign: "center", borderBottom: "1px solid #ccc"}}>
                                <tr>
                                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "80%" }}>
                                        <div>
                                            Hora de inicio: {formatDate}
                                        </div>
                                        <div>
                                            Lugar: {formatLocation}
                                        </div>
                                        <div>
                                            Tipo: {typeFormat}
                                        </div>
                                        <div>
                                            Duración: {this.props.selectedActivityDict.duration} minutos
                                        </div>
                                        <br/>
                                    </td>
                                </tr>
                            </table>
                            <br/>
                            <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "100%", textAlign: "center" }}>
                                <tr>
                                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "10%" }}></td>
                                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "80%" }}>
                                        <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "100%" }}>
                                            <tr>
                                                <td>
                                                    Comentarios
                                                </td>
                                            </tr>
                                            <tr nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "100%" }}>
                                                <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "100%" }}>
                                                    <input
                                                        ref={(comments) => { this.comments = comments }}
                                                        nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ color: "grey", fontSize: 14, width: "100%" }}
                                                        className="form-control form-control-lg"
                                                        defaultValue={this.props.defaultComments}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                            </tr>
                                        </table>
                                    </td>
                                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "10%" }}></td>
                                </tr>
                                <tr>
                                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "10%" }}></td>
                                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "80%" }}>
                                        <br></br>
                                    </td>
                                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "10%" }}></td>
                                </tr>
                                <tr>
                                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "10%" }}></td>
                                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "80%" }}>
                                        <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "100%" }}>
                                            <tr>
                                                <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "40%" }}>
                                                    <button nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" 
                                                        style={{ width: "100%", backgroundColor: "green", border: "none", fontSize: 16 }} 
                                                        type="submit"
                                                        className="btn btn-primary btn-block btn-lg">
                                                        Confirmar
                                                    </button>
                                                </td>
                                                <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "20%" }}>
                                                </td>
                                                <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style = {{ width: "40%" }}>
                                                    <button type="button" className="btn btn-primary btn-block btn-lg" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "100%", backgroundColor: "red", border: "none", fontSize: 16 }} onClick={this.props.onClose}>
                                                        Cerrar
                                                    </button>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "10%" }}></td>
                                </tr>
                            </table>
                        </form>
                        <br></br>
                    </section>
                </div>
            );
        // } catch (err) {
        //     return (<div>Error al cargar el componente</div>)
        // }
    }
}

export default SendSmsActivity;
