import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';

class CheckState extends Component {
    
    constructor (props) {
        super(props)
        this.state = {      
            chargeStatus: 0,

        };

    }

    render() {
        try{
            let status = this.props.chargeStatusTGD
            if(status < 100){
                return (
                    <div nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width: '200px', height: '50px', position: 'fixed', bottom: '20px', right: '20px', borderRadius:"10px", border:"1px solid silver", backgroundColor: '#e6e6fa'}}>
                        <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width: '90%', height: '30px', marginLeft:"5%", marginRight:"5%", marginTop:"10px"}}>
                            <tbody nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg">
                                <tr nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg">
                                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width: '100%', height: '100%', fontSize:"14px", textAlign:"center", verticalAlign: 'middle'}}>
                                        Procesando archivos: {status}%
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            } else {
                return (
                    <div nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width: '200px', height: '100px', position: 'fixed', bottom: '20px', right: '20px', display: 'flex', flexDirection: 'column', alignContent:"center", borderRadius:"10px", border:"1px solid silver", backgroundColor: '#e6e6fa'}}>
                        <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width: '90%', height: '30%', marginLeft:"5%", marginRight:"5%", marginTop:"7%"}}>
                            <tbody nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg">
                                <tr nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg">
                                    <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width: '100%', height: '100%', fontSize:"14px", textAlign:"center"}}>
                                        Procesando archivos: 100%
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{width: '90%', height: '40%', marginLeft:"5%", marginRight:"5%"}}> 
                            <button type="button" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"100%", height:"100%", textAlign:'center', textJustify:'center', fontSize:"16px", verticalAlign: 'middle', backgroundColor:"green"}} className="btn btn-primary btn-block btn-lg" 
                            onClick={(event) => {
                                window.location.reload()}}>Recargar Página</button>
                        </div>
                    </div>
                )
            }
            
            
        }catch(err){
            return(<div>Error al cargar el componente</div>)
        }
    }
}

export default CheckState;