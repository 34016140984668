import React, { Component } from 'react';

class ErrorExpirated extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.redirectToPage();
    }, 3000); // 3000 milliseconds = 3 seconds
  }

  redirectToPage = () => {
    this.props.errorStatus();
    window.location.href = 'http://drive-team.es';
  };

  render() {
    window.scrollTo(0, 0);
    return (
      <div className="card mb-4">
        <div className="card-body">
          <br />
          <br />
          <br />
          <br />
          <br />
          <table className="table table-borderless text-muted text-center">
            <tbody>
              <tr>
                <td>
                  <h2>{this.props.errorMsg}</h2>
                </td>
                <td>
                  {/*
                    !product.purchased ? (
                      <button
                        nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg"
                        style={{ width: '100%', fontSize: 16 }}
                        className="btn btn-primary btn-block btn-lg"
                        onClick={this.redirectToPage}
                      >
                        Volver
                      </button>
                    ) : null
                  */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default ErrorExpirated;
