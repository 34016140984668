import React, { useState, useEffect } from 'react';

function AskInstall() {
    //console.log("me estásn llamando")
  const [installPrompt, setInstallPrompt] = useState(null);
  //console.log("me han llamado")
  useEffect(() => {
    //console.log("voy a hacer el handle")
    const handleBeforeInstallPrompt = (event) => {
        //console.log("voy a llamar a setIntall")
      event.preventDefault();
      //console.log(event)
      
      setInstallPrompt(event);
    };
   //console.log("voy a addEventListener")
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
        //console.log("haciendo el remove raro")
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
  
    if (installPrompt) {
      //console.log(installPrompt)
      installPrompt.prompt();
      installPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setInstallPrompt(null);
      });
    }
  };
 console.log("voy ha hacer el return")
  return (
    <div  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width: "100%", textAlign:"center"}}>
      {installPrompt && (
        <button className="btn btn-primary btn-block btn-lg" style={{marginTop:'10%', backgroundColor:'#52B61C', borderColor:'#52B61C', fontSize:14}} onClick={handleInstallClick}>Instala Drive-Team</button>
      )}
    </div>
  );
}

export default AskInstall;