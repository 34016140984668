import React, { Component, useContext } from 'react'
import { UserContext } from './../context/UserContext'

//Componente que se encarga de la funcionalidad del botón "Cerrar Sesión"
const Logout = () => {
    const [token, setToken] = useContext(UserContext);

    //Borrar usuario actual del local data y recargar la página
    const handleLogout = () => {
        console.log("voy a borrar")
        let estadoUsuario = localStorage.getItem("TraxainUserToken");
        setToken(null);
        comprobarEstado();

        function comprobarEstado() {
            if (estadoUsuario === "null" || estadoUsuario === null) {
                window.location.reload()
            }
            else {
                estadoUsuario = localStorage.getItem("TraxainUserToken");
                setTimeout(() => comprobarEstado(), 50);
            }
        }
    };

    //Comprueba si se ha pulsado el botón, si se ha pulsado borrar el botón
    if (token && token !== "null") {
        return (
            <div nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ "width": "100%" }}>
                {token && (
                    <button class="btn btn-danger" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ "width": "66%" }} onClick={handleLogout}>
                        Cerrar sesión
                    </button>
                )}
            </div>
        )
    }
    else {
        return (
            <div></div>
        )
    }

};

export default Logout;