import React, { Component } from "react";
import './modal.css';

class ModalChangePass extends Component {
  async changePass(previousPass, password1, password2){
    try{
      if(await this.props.getOldPass(previousPass)){
        this.setState({incorrectPass:false})
        if (password1===password2 && password1.length>=8){
          this.setState({unequal:false})
          this.props.assignNewPass(password1)
        }else{
          this.setState({unequal:true})
        }
      }else{
        this.setState({incorrectPass:true})
        this.setState({unequal:false})
      }
    }catch(err){
      this.props.showError()
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      unequal:false,
      incorrectPass:false
    }
  
    this.errorStatus = this.changePass.bind(this)
  }

  render (){
    try{
      var show = this.props.show
      var children = this.props.children
      let error = <div></div>

      if (this.state.incorrectPass){
        error = <div nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"red"}}>Contraseña actual incorrecta</div>
      } else if (this.state.unequal){
        error = <div nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"red"}}>La nueva contraseña debe coincidir y tener al menos 8 caracteres</div>
      }

      var showHideClassName = show ? "modal display-block" : "modal display-none";
      return (
        <div className={showHideClassName} nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{textAlign:"center"}}>
          <section className="modal-main" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{textAlign:"center",width:"66%", borderRadius:"10px"}}>
            <br></br>
            <h5>{children}</h5>
            <form  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{textAlign:"center",width:"100%"}} onSubmit={(event) => {
              event.preventDefault()
              let previousPass = this.previousPass.value
              let password1 = this.password1.value
              let password2 = this.password2.value
              this.changePass(previousPass, password1, password2)
            }}>
              <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"100%",textAlign:"center"}}>
                <tr>
                  <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10%"}}></td>
                  <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"80%"}}>
                    <table nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width:"100%"}}>
                      <tr>
                        <td>Contraseña actual</td>
                      </tr>
                      <tr> 
                        <td>
                          <input type="password" ref={(previousPass) => { this.previousPass = previousPass }} nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:14}} className="form-control form-control-lg"/>
                        </td> 
                      </tr> 
                      <tr>
                        <td>Nueva Contraseña</td>
                      </tr>
                      <tr> 
                        <td>
                          <input type="password" ref={(password1) => { this.password1 = password1 }} nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:14}} className="form-control form-control-lg"/>
                        </td> 
                      </tr> 
                      <tr>
                        <td>
                          Confirma la contraseña
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="password" ref={(password2) => { this.password2 = password2 }} nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:14}} className="form-control form-control-lg"/>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10%"}}></td>
                </tr>
                <tr>
                  <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10%"}}></td>
                  <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"80%"}}>
                    {error}
                    <br></br>
                  </td>
                  <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10%"}}></td>
                </tr>
                <tr>
                  <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10%"}}></td>
                  <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"80%"}}>
                    <table  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{ width:"100%"}}>
                      <tr>
                        <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"40%"}}>
                          <button type="button" className="btn btn-primary btn-block btn-lg" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width: "100%", backgroundColor:"red", border:"none", fontSize:16}} onClick={(event) => {this.props.onClose()}}>
                            Cancelar
                          </button>
                        </td>
                        <td  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"20%"}}></td>
                        <td  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"40%"}}>
                          <button  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width: "100%", backgroundColor:"green", border:"none", fontSize:16}} type="submit" className="btn btn-primary btn-block btn-lg">
                            Confirmar
                          </button>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"10%"}}></td>
                </tr>
              </table>
            </form>
            <br></br>
          </section>
        </div>
      );
    }catch(err){
      return(<div>Error al cargar el componente</div>)
    }
  }
}

export default ModalChangePass;