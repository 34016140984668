import React from 'react';
import notfound from '../notfound.webp';

function Page404(){
    return(<div nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{textAlign:"center"}}>
        <br></br><br></br><br></br><br></br><br></br><br></br>
        <h1>Página no encontrada</h1>
        <h3>No hemos encontrado esta dirección, vuelve a  <a href="https://drive-team.es">Drive-Team.es </a></h3>
        <br></br>
        <img style={{maxWidth:"400px", padding: "20px"}} src={notfound} alt="notfound"/>
    </div>)
}

export default Page404