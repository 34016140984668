import React, { Component } from 'react'
import Select from 'react-select';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

class Actions extends Component {
  componentDidMount() {
    const { time } = this.props;
    setTimeout(() => this.setState({ render: true }), (time || 400));
  }

  constructor(props) {
    super(props)
    this.state = {
      type:'name',
      render:false
    }
    this.chooseSearchType = this.chooseSearchType.bind(this)
  }

  async chooseSearchType(type){
    await this.setState({type:type.value}) 
  }

  render() {
    let actions
    const { render } = this.state;

    var graphDisplay =
      <button className='btn btn-primary' style={{width:"100%", height:"42px", fontSize:16}} onClick={(event) => {
        this.props.showGraphic(0,0,[],false);}}>
      <span style={{ marginRight: "5px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-graph-up" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M0 0h1v16H0V0zm1 15h15v1H1v-1zm15 0h-4V8.5h4V15zm-5.5 0H8V3.5h2.5V15zM8 2.5h2.5V15H8V2.5zm-3.5 12H1V1.5h3.5V14z"/>
        </svg>
      </span>
        Gráfico
      </button>

    // if (this.props.device !== "desktop") {
    //   graphDisplay = <div></div>
    // }


    if(this.props.userRole === "admin"){
      actions = 
        <table className="table"   nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"100%"}}>
          <tbody   nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"100%"}}>
            <tr nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"100%"}}>
              <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"49%"}}>
                <form  onSubmit={(event) => {
                  event.preventDefault() 
                  this.props.actualizarEstadoYAlmacenar()
                  this.props.usersManagerCount(0,0,"text", {})
                }}>            
                  <button type="submit" 
                          nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" 
                          style={{width:"100%",fontSize:16}} 
                          className="btn btn-primary btn-block btn-lg">
                            Ver gerentes
                  </button>
                </form>          
              </td>
              <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"2%"}}></td>
              <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"49%"}}>        
                <form  onSubmit={(event) => {
                  event.preventDefault()
                  this.props.actualizarEstadoYAlmacenar()
                  this.props.showCreationManager()
                }}>
                  <button type="submit" 
                          nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" 
                          style={{width:"100%",fontSize:16}} 
                          className="btn btn-primary btn-block btn-lg">
                            Crear gerente
                  </button>
                </form>
              </td>
            </tr>
            <tr nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"100%"}}>
              <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"49%"}}>
                <form  onSubmit={(event) => {
                  event.preventDefault()
                  this.props.actualizarEstadoYAlmacenar() 
                  this.props.usersEmployeeCount(0,0,"text", {})
                }}>            
                  <button type="submit" 
                          nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" 
                          style={{width:"100%",fontSize:16}} 
                          className="btn btn-primary btn-block btn-lg">
                            Ver empleados
                  </button>
                </form>          
              </td>
              <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"2%"}}></td>
              <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"49%"}}>        
                <form  onSubmit={(event) => {
                  event.preventDefault()
                  this.props.actualizarEstadoYAlmacenar()
                  this.props.showCreationFields()
                }}>
                  <button type="submit" 
                          nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" 
                          style={{width:"100%",fontSize:16}} 
                          className="btn btn-primary btn-block btn-lg">
                            Crear empleado
                  </button>
                </form>
              </td>
            </tr>
            <tr nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"100%"}}>
              <td  className="form-control form-control-lg" style={{"width":"100%"}}>
                <input
                  type='text'
                  onKeyUp={(event) => this.props.searchManual(event.target.value)}
                  className="form-control form-control-lg"
                  placeholder="Busca nombre, apellidos o tarjeta"
                  required
                />
              </td>
              <td></td>
               <td className="form-control-lg" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey", width:"100%", height:"100%"}}>
                {graphDisplay}          
              </td>
            </tr>
          </tbody>
        </table>
    } else {
      actions = 
        <table className="table"   nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"100%"}}>
          <tbody   nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"100%"}}>
            <tr nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"100%"}}>
              <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"49%"}}>
                <form  onSubmit={(event) => {
                  event.preventDefault() 
                  this.props.usersEmployeeCount(0,0,"text", {})
                }}>            
                  <button type="submit" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"100%",fontSize:16}} className="btn btn-primary btn-block btn-lg">Ver empleados</button>
                </form>          
              </td>
              <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"2%"}}></td>
              <td nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{"width":"49%"}}>        
                <form  onSubmit={(event) => {
                  event.preventDefault()
                  this.props.showCreationFields()
                }}>
                  <button type="submit" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"100%",fontSize:16}} className="btn btn-primary btn-block btn-lg">Crear empleado</button>
                </form>
              </td>
            </tr>
            <tr nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{width:"100%", height:"100%"}}>
              <td  className="form-control form-control-lg" style={{width:"100%", height:"100%"}}>
                <div style={{width:"100%", height:"100%"}}>
                  <input
                    onKeyDown={(e) => this.props.searchManual(e, this.state.type)} 
                    className="form-control form-control-lg"
                    placeholder="Busca por nombre, identificador o No de Tarjeta"
                    required/>
                </div>
              </td>
              <td></td>
              <td className="form-control-lg" nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey", width:"100%", height:"100%"}}>
                <Dropdown          
                  onChange={ this.chooseSearchType}
                  defaultOption={this.state.type}  
                  nonce="kjcdhjkehfkldgso2379389xbagfjtdfdfg" style={{color:"grey",fontSize:10}}
                  options ={[
                    { value: "name", label: "Nombre"},
                    { value: "apellidos", label: "Apellido" }
                  ]}
                />          
              </td>
            </tr>
          </tbody>
        </table>
    }

    if (!render) {
      return null;
    }

    return (<div>{actions}</div>)
  }
}

export default Actions;